import React, { useState, useEffect } from 'react'
import HomePage from './index'
import axios from 'axios'
import { useLangContext } from '../context/lang.context'
import queryString from 'query-string'
import { useLocation } from '@reach/router'
import Popup from '../components/Popup'
import Seo from '../components/Seo'
import Headline from '../components/Headline'
import Button from '../components/Button'
import Spinner from '../components/Spinner'

const messages = [
  {
    title: {
      en: 'Newsletter',
      es: 'Newsletter',
      ro: 'Newsletter',
    },
    desc: {
      en: 'Your address is being verified in the subscriber base. The process may take a few seconds ...',
      es: 'Tu dirección está siendo verificada en la base de suscriptores. El proceso puede tardar unos segundos...',
      ro: 'Adresa dumneavoastră este în curs de verificare în baza de abonați. Procesul poate dura câteva secunde...',
    },
  },
  {
    title: {
      en: 'Thank you!',
      es: '¡Gracias!',
      ro: 'Mulțumesc!',
    },
    desc: {
      en: 'Excellent! Your address has been successfully added to the list of subscribers. Soon the latest posts will appear in your mailbox!',
      es: '¡Genial! Su dirección ha sido agregada con éxito a la lista de suscriptores. ¡Pronto aparecerá la información más reciente en su buzón!',
      ro: 'Excelent! Adresa dvs. a fost adăugată cu succes la lista de abonați. În curând, cele mai recente postări vor apărea în căsuța dvs. poștală!',
    },
  },
  {
    title: {
      en: 'Subscribing!',
      es: '¡Te estás suscribiendo!',
      ro: 'Subscribing!',
    },
    desc: {
      en: 'Your email address already exists on the list of subscribers to the Omida newsletter. Soon the latest information will appear in your mailbox!',
      es: 'Su dirección de correo electrónico está en la lista de suscriptores del boletín de Omida. ¡Pronto aparecerá la información más reciente en su buzón!',
      ro: 'Adresa ta de e-mail există deja pe lista de abonați la buletinul informativ Omida. În curând vor apărea cele mai recente informații în căsuța dvs. poștală!',
    },
  },
  {
    title: {
      en: 'Failure!',
      es: '¡Falla!',
      ro: 'Eșec!',
    },
    desc: {
      en: 'An error occurred while adding an address to the subscriber list. Please try again later. Server message: ',
      es: 'Ocurrió un error al agregar una dirección a la lista de suscriptores. Por favor, inténtelo de nuevo más tarde. Mensaje del servidor: ',
      ro: 'A apărut o eroare la adăugarea unei adrese la lista de abonați. Vă rugăm să încercați din nou mai târziu. Mesaj server:',
    },
  },
  {
    title: {
      en: 'Invalid data',
      es: 'Datos inválidos',
      ro: 'Date nevalide',
    },
    desc: {
      en: 'It looks like the data provided is incorrect. Please try again later. Server message: ',
      es: 'Parece que los datos proporcionados son incorrectos. Por favor, inténtelo de nuevo más tarde. Mensaje del servidor: ',
      ro: 'Se pare că datele furnizate sunt incorecte. Vă rugăm să încercați din nou mai târziu. Mesaj server:',
    },
  },
  {
    title: {
      en: 'Not found',
      es: 'Extraviado',
      ro: 'Nu a fost găsit',
    },
    desc: {
      en: 'Your email address is not on the list of subscribers to the Omida newsletter. Server message: ',
      es: 'Su dirección de correo electrónico no está en la lista de suscriptores del boletín de Omida. Mensaje del servidor:',
      ro: 'Adresa ta de e-mail nu se află pe lista de abonați la buletinul informativ Omida. Mesaj server:',
    },
  },
]

const Newsletter = () => {
  const { lang } = useLangContext()
  const location = useLocation()
  // const path = location.pathname
  const params = queryString.parse(location.search)
  const token = params.token
  const email = params.email
  const campaignToken = params.campaignToken

  const [open, setOpen] = useState(true)
  const [code, setCode] = useState(0)
  const [serverMessage, setServerMessage] = useState('')

  /* es-lint disable */
  useEffect(() => {
    if (!token || !email || !campaignToken) return setOpen(false)
    axios({
      method: 'POST',
      url:
        process.env.NODE_ENV === 'development'
          ? 'http://localhost:5000/omida/newsletter/confirm'
          : 'https://cleverserver.appclever.pl/omida/newsletter/confirm',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      data: {
        email,
        token,
        campaignToken,
      },
    })
      .then((res) => {
        console.log(res)
        setServerMessage(res.data.message)
        setCode(res.data.code)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])
  /* es-lint enable */

  return (
    <>
      <Seo>
        <meta name='robots' content={'noindex, nofollow'} />
      </Seo>
      <HomePage />
      <Popup center open={open} setOpen={setOpen}>
        <Headline
          h={1}
          title={messages[code].title[lang]}
          desc={messages[code].desc[lang] + (code > 2 ? serverMessage : '')}
          gradient
          large
          maxWidth
        />
        <Button
          noMargin
          reactiveBlack
          action={() => setOpen(false)}
          active={code > 0}
          extraCss={sButton}>
          {code === 0 && (
            <>
              <Spinner extraCss={sSpinner} />
            </>
          )}
          {code > 0 && 'OK'}
        </Button>
      </Popup>
    </>
  )
}

const sSpinner = {
  transform: 'scale(0.33)',
}

const sButton = {
  minHeight: 48,
  minWidth: 144,
  maxHeight: 48,
  marginTop: '2.5rem',
  position: 'relative',
}

/*
 <form
          action='https://app.getresponse.com/add_subscriber.html'
          accept-charset='utf-8'
          method='post'>
          firstname: <input type='text' name='first_name' />
          <br />
          lastname: <input type='text' name='last_name' />
          <br />
          email: <input type='text' name='email' />
          <br />
          Telefon: <input name='custom_phone' type='text' />
          <br />
          Firma: <input name='custom_company' type='text' />
          <br />
          nip: <input name='custom_nip' type='text' />
          <br />
          <input type='hidden' name='campaign_token' value='jAAKH' />
          <input type='hidden' name='start_day' value='0' />
          <input type='submit' value='Subscribe' />
        </form>
*/

export default Newsletter
